import Distance from "@components/Distance";
import usePresence from "@hooks/usePresence";
import { AnyAccount, PresenceType } from "@lib/models";
import getStatusColor from "@lib/util/getStatusColor";
import { Box, Typography, styled } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";

function getStatusDotStyles(onlineStatus: PresenceType) {
	const colorMap = {
		online: { color: palette.indicatorActive, backgroundColor: palette.indicatorActive },
		idle: { color: palette.OnSurfaceSecondary, backgroundColor: palette.OnSurfaceSecondary },
		away: { color: palette.OnSurfaceSecondary, backgroundColor: palette.OnSurfaceSecondary },
		offline: { border: `${palette.OnSurfaceSecondary} 1px solid` },
	};

	return colorMap[onlineStatus];
}

const Dot = styled("span", {
	shouldForwardProp: (prop) => prop !== "onlineStatus",
})<{ onlineStatus: PresenceType }>(({ onlineStatus }) => {
	if (onlineStatus !== "online") {
		return null;
	}

	return {
		...getStatusDotStyles(onlineStatus),
		width: "8px",
		height: "8px",
		marginRight: "10px",
		borderRadius: "4px",
	};
});

export default function OnlineStatus({ account }: { account: AnyAccount }) {
	const { t } = useTranslation("common");
	const accountPresence = usePresence(account);
	const statusMessage: string = t(`presence.presence_${accountPresence}`);

	return (
		<Box sx={{ display: "flex", alignItems: "center", m: 0, mt: 1.5 }} component="p">
			<Dot onlineStatus={accountPresence} />
			<Typography
				component="span"
				sx={(theme) => {
					return {
						color: getStatusColor(accountPresence),
						mr: theme.spacing(3),
						fontSize: "12px",
						fontWeight: 500,
					};
				}}
			>
				{statusMessage}
			</Typography>
			<Distance account={account} />
		</Box>
	);
}
