import Alert from "@components/Alert";
import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import DialogButton from "@components/DialogButton";
import { Box, DialogActions } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";
import { NotificationMessageProps } from "../types";

export default function UpdateIOSMessage({ onClose: handleClose }: NotificationMessageProps) {
	const { t } = useTranslation("common");
	return (
		<Alert
			title={t("outdated_ios_banner.banner_title")}
			body={t("outdated_ios_banner.banner_subtitle")}
			button={
				<DialogButton
					onClose={handleClose}
					renderDialog={(open, onClose) => {
						return (
							<Dialog open={open} onClose={onClose} title={t("outdated_ios_dialog.dialog_title")}>
								<Box component="ol" sx={{ m: 0, pl: "1.5em" }}>
									<li>{t("outdated_ios_dialog.update_ios_step_1")}</li>
									<li>{t("outdated_ios_dialog.update_ios_step_2")}</li>
									<li>{t("outdated_ios_dialog.update_ios_step_3")}</li>
									<li>
										<Trans t={t} i18nKey={"outdated_ios_dialog.update_ios_step_4"} components={[<strong key="0" />]} />
									</li>
								</Box>
								<DialogActions>
									<Button variant="primary" onClick={onClose}>
										{t("close_button")}
									</Button>
								</DialogActions>
							</Dialog>
						);
					}}
					renderButton={(onOpen) => (
						<Button onClick={onOpen} size="xxs" variant="primary ghost">
							{t("outdated_ios_banner.banner_button_label")}
						</Button>
					)}
					dialogRoutingName="outdated-ios"
				/>
			}
		/>
	);
}
