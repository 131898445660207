import { Dict, Location, UserAccount } from "@lib/models";
import mixpanel, { Mixpanel } from "mixpanel-browser";
import { Statsig } from "statsig-react";

const OPT_IN_ANALYTICS_KEY = "/lib/util/trackAnalytics:optin";
const mixpanel_token = process.env.NEXT_PUBLIC_MIXPANEL_TOKEN || "";
const mixpanel_proxy = process.env.NEXT_PUBLIC_MIXPANEL_PROXY || "";
const mixpanelConfig: {
	distinctId: string | undefined;
	$latitude: number | undefined;
	$longitude: number | undefined;
} = {
	distinctId: undefined,
	$latitude: undefined,
	$longitude: undefined,
};

if (typeof window !== "undefined") {
	const mixConfig = {
		debug: process.env.NODE_ENV === "production" ? false : true,
		loaded(mp: Mixpanel) {
			mixpanelConfig.distinctId = mp.get_distinct_id();
		},
		api_host: mixpanel_proxy,
		disable_persistence: window.localStorage.getItem(OPT_IN_ANALYTICS_KEY) !== "true",
	};

	mixpanel.init(mixpanel_token, mixConfig);
}

export function hasOptedIn() {
	return window.localStorage.getItem(OPT_IN_ANALYTICS_KEY) === "true";
}

export function enableTrackingPersistence({ optedIn }: { optedIn: boolean }) {
	mixpanel.set_config({
		disable_persistence: false,
	});
	if (optedIn) {
		window.localStorage.setItem(OPT_IN_ANALYTICS_KEY, "true");
		track("accept_privacy_banner");
	}
}

export function track(eventName: string, properties?: Dict, options: { statsig?: boolean } = {}) {
	const { statsig = false } = options;

	if (!mixpanelConfig.distinctId) {
		return;
	}

	const { $latitude, $longitude } = mixpanelConfig;
	mixpanel.track(eventName, {
		...properties,
		$latitude,
		$longitude,
	});

	// events sent in `onLoad.ts` are currently skipped since Statsig is not yet initialized
	if (statsig) {
		Statsig.logEvent(
			eventName,
			undefined,
			typeof properties === "object" ? (properties as Record<string, string>) : properties,
		);
	}
}

export function identify(profile: UserAccount) {
	if (mixpanel.get_distinct_id() !== profile.uuid) {
		mixpanel.identify(profile.uuid);
	}
}

export function unIdentify() {
	mixpanel.reset();
}

export function setUserProperties(locale: string) {
	mixpanel.people.set({ distinctId: mixpanelConfig.distinctId, locale });
}

export function setMixpanelLocation(latAndLng: Location) {
	mixpanelConfig.$latitude = latAndLng.lat;
	mixpanelConfig.$longitude = latAndLng.lng;
}
