import { Button } from "@components/Button";
import DialogButton from "@components/DialogButton";
import GenericMedia from "@components/GenericMedia";
import XSquareIcon from "@components/icons/XSquareIcon";
import useIsBlurred from "@hooks/useIsBlurred";
import { UserShort } from "@lib/models";
import { Box, Modal, Typography } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import ReplyButton from "./ReplyButton";
import ReplyForm from "./ReplyForm";

type ITMReplyProps = {
	profile: UserShort;
	useTextButton?: boolean;
};

export default function ITMReply({ profile, useTextButton }: ITMReplyProps) {
	const { t } = useTranslation("common");
	const nsfwModeOn = useIsBlurred();

	const in_the_moment_status = profile.in_the_moment_status;

	if (in_the_moment_status == null) {
		return null;
	}

	return (
		<DialogButton
			dialogRoutingName={`itm-reply-${in_the_moment_status.uuid}`}
			renderDialog={(open, onClose) => {
				return (
					<Modal open={open} onClose={onClose}>
						<Box
							role="dialog"
							aria-label={t("in_the_moment.itm_reply_input_label")}
							aria-modal="true"
							tabIndex={-1}
							sx={{
								position: "absolute",
								top: "env(safe-area-inset-top, 0)",
								right: "env(safe-area-inset-right, 0)",
								bottom: "env(safe-area-inset-bottom, 0)",
								left: "env(safe-area-inset-left, 0)",
								pointerEvents: "none",
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-end",
							}}
						>
							<Box
								sx={(theme) => ({
									display: "flex",
									gap: theme.spacing(1),
									alignItems: "center",
									backgroundColor: palette.SurfaceContainerMid,
									padding: theme.spacing(1, 1.5),
									pointerEvents: "auto",
								})}
							>
								<Box
									aria-hidden
									sx={{
										backgroundColor: palette.Secondary,
										width: "2px",
										height: "50px",
									}}
								/>
								{in_the_moment_status.blurhash == null ? null : (
									<Box sx={{ height: "50px", width: "50px", flex: "0 0 50px" }}>
										<GenericMedia
											alt={
												in_the_moment_status.video != null
													? t("in_the_moment.current_video_alt")
													: t("in_the_moment.current_photo_alt")
											}
											media={{
												blurhash: in_the_moment_status.blurhash,
												videoUrl: nsfwModeOn ? null : in_the_moment_status.video,
												imageUrl: nsfwModeOn ? null : in_the_moment_status.full,
											}}
										/>
									</Box>
								)}
								<Box sx={{ flex: "1 1 auto" }}>
									<Typography component="h2" variant="h5" fontSize="12px" fontWeight="500">
										{profile.is_anon
											? t("anon_display_name")
											: profile.display_name === ""
												? t("no_username_placeholder")
												: profile.display_name}
									</Typography>
									{in_the_moment_status.message === "" ? null : (
										<Typography fontSize="14px">{in_the_moment_status.message}</Typography>
									)}
								</Box>
								<Button
									icon
									onClick={onClose}
									variant="secondary ghost"
									aria-label={t("close_button_label")}
									sx={{ flex: "0 0 auto" }}
								>
									<XSquareIcon />
								</Button>
							</Box>
							<Box sx={{ pointerEvents: "auto", backgroundColor: palette.Surface }}>
								<ReplyForm uuid={profile.uuid} onSend={onClose} />
							</Box>
						</Box>
					</Modal>
				);
			}}
			renderButton={(onOpen) => (
				<ReplyButton inTheMomentContent={in_the_moment_status} onOpen={onOpen} useTextButton={useTextButton} />
			)}
		/>
	);
}
