import AppLayout from "@components/AppLayout";
import Delayed from "@components/Delayed";
import InTheMomentControl from "@lib/feature/map/mapbox/InTheMomentControl";
import { Box, CircularProgress, styled } from "@mui/material";
import palette from "@styles/palette";
import { useMemo, useRef } from "react";
import FeedEnd from "./FeedEnd";
import ITMCFeedItem from "./ITMCFeedItem";
import NewFeedItemsIndicator from "./NewFeedItemsIndicator";
import NoFeedItemsPlaceholder from "./NoFeedItemsPlaceholder";
import useFeedItems from "./useFeedItems";

const ItemList = styled("ol")(({ theme }) => ({
	flex: "0 1 auto",
	margin: 0,
	display: "flex",
	flexDirection: "column",
	padding: 0,
	gap: 0,
	"& > li": {
		listStyle: "none",
		margin: 0,
		borderBottom: `1px solid ${palette.OnSurfaceTertiary}`,
		padding: theme.spacing(2.5, 0),
	},
}));

type FeedState = "RESOLVED" | "LOADING" | "EMPTY";

const noPostsStyle = { justifyContent: "center", alignItems: "center", height: "100%" };

export default function Feed() {
	const feed = useFeedItems();
	const scrollableAreaRef = useRef<HTMLDivElement>(null);

	const feedState: FeedState = useMemo(() => {
		if (feed.loading) {
			return "LOADING";
		}
		if (feed.feedItems.length > 0) {
			return "RESOLVED";
		}
		return "EMPTY";
	}, [feed]);

	return (
		<AppLayout>
			<NewFeedItemsIndicator />
			<Box
				ref={scrollableAreaRef}
				sx={(theme) => ({
					flex: "1 1 auto",
					overflow: "auto",
					width: "100%",
					display: "flex",
					flexDirection: "column",
					mb: theme.spacing(-7),
					...(feedState === "EMPTY" ? noPostsStyle : {}),
				})}
			>
				<Box sx={{ mx: "auto", maxWidth: "600px", width: "100%" }}>
					{feedState === "LOADING" ? (
						<Delayed>
							<Box
								sx={{
									m: 0,
									p: 0,
									flex: "1 1 auto",
									display: "flex",
									flexDirection: "column",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<CircularProgress />
							</Box>
						</Delayed>
					) : feedState === "EMPTY" ? (
						<NoFeedItemsPlaceholder />
					) : (
						<>
							<ItemList>
								{feed.feedItems.map((post) => {
									return (
										<li key={post.uuid}>
											<ITMCFeedItem feedItem={post} />
										</li>
									);
								})}
							</ItemList>
							<FeedEnd />
						</>
					)}
					<Box sx={(theme) => ({ position: "absolute", bottom: theme.spacing(12), right: 0 })}>
						<InTheMomentControl source={"feed"} />
					</Box>
				</Box>
			</Box>
		</AppLayout>
	);
}
