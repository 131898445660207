import Alert from "@components/Alert";
import PhotoInput from "@components/PhotoInput";
import useAlertState from "@lib/feature/alert/useAlertState";
import useAutoDismissValid from "@lib/feature/alert/useAutoDismissValid";
import { UploadPhotoDataType } from "@lib/models";
import { createObjectURL, revokeObjectURL } from "@lib/util/objectUrlWrapper";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, IconButton } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useEffect, useId, useMemo, useState } from "react";
import { useField } from "react-final-form";

const previewWidth = 135;

function identity<T>(a: T) {
	return a;
}

function PreviewSizedImage({ value }: { value: UploadPhotoDataType }) {
	const { t } = useTranslation("common");
	const [nextImageUrl, setNextImageUrl] = useState<string | undefined>(undefined);
	useEffect(() => {
		const url = createObjectURL(value.file);
		setNextImageUrl(url);
		return () => {
			setNextImageUrl(undefined);
			revokeObjectURL(url);
		};
	}, [value]);

	const [imageSize, setImageSize] = useState({ width: 400, height: 400 });
	const properties = useMemo(() => {
		const scale = previewWidth / value.width;
		return {
			position: "relative",
			width: scale * imageSize.width,
			height: scale * imageSize.height,
			left: scale * -value.x,
			top: scale * -value.y,
		};
	}, [value, imageSize]);
	return (
		<Box
			component="img"
			src={nextImageUrl}
			alt={t("in_the_moment.cropped_photo_preview_alt")}
			onLoad={(event) => {
				const image = event.target as HTMLImageElement;
				setImageSize({
					width: image.naturalWidth,
					height: image.naturalHeight,
				});
			}}
			sx={properties}
		/>
	);
}

type PhotoFieldProps = {
	disabled?: boolean;
};

export default function PhotoField({ disabled: externallyDisabled = false }: PhotoFieldProps) {
	const { t } = useTranslation("common");
	const helperTextId = `${useId()}_photo_helper_text`;

	const { input, meta } = useField<UploadPhotoDataType>("image", {
		format: identity,
	});

	const disabled = externallyDisabled || meta.submitting;

	const [alert, updateAlert] = useAutoDismissValid(useAlertState());

	return (
		<Box
			sx={{
				position: "relative",
			}}
		>
			{alert == null ? null : (
				<Alert
					key={alert.key}
					severity={alert.severity}
					body={alert.children}
					sx={{ m: 0, position: "absolute", top: 0, left: 0, width: "100%", zIndex: "100" }}
					onClose={() => {
						updateAlert();
					}}
				/>
			)}
			<Box
				sx={{
					position: "relative",
					width: `${previewWidth}px`,
					height: `${previewWidth}px`,
					mx: "auto",
				}}
			>
				{input.value != null ? (
					<Box
						sx={{
							position: "absolute",
							top: "0px",
							right: "0px",
							bottom: "0px",
							left: "0px",
							overflow: "hidden",
						}}
					>
						<PreviewSizedImage value={input.value} />
					</Box>
				) : null}
				<PhotoInput
					helperTextId={helperTextId}
					onError={(error) => {
						updateAlert(error);
					}}
					onUpload={(uploadData) => {
						input.onChange(uploadData);
					}}
					inputLabelText={t("in_the_moment.photo_input_label")}
					cropButtonLabel={t("done_button")}
					inTheMoment={true}
					disabled={disabled}
				/>
				{input.value == null ? null : (
					<IconButton
						color="secondary"
						size="small"
						aria-label={t("in_the_moment.remove_selected_photo_button_screen_reader_label")}
						onClick={() => {
							input.onChange(undefined);
						}}
						disabled={disabled}
						sx={{
							position: "absolute",
							color: "common.white",
							top: (theme) => theme.spacing(1),
							left: (theme) => theme.spacing(1),
						}}
					>
						<DeleteIcon />
					</IconButton>
				)}
			</Box>
		</Box>
	);
}
