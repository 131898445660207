import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import useAuth from "@hooks/useAuth";
import RegisterAnonModal from "@lib/feature/anon/RegisterAnonModal";
import { track } from "@lib/util/trackAnalytics";
import { DialogActions, Link, MenuItem } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";
import { useState } from "react";

type AnonExitButtonProps = {
	onClose: () => void;
};

export default function AnonExitButton({ onClose }: AnonExitButtonProps) {
	const { t } = useTranslation("common");
	const [open, setOpen] = useState(false);
	const { onLogout } = useAuth();

	function onCloseModal() {
		setOpen(false);
	}

	return (
		<>
			<MenuItem
				component="span"
				key="exit-delete-button"
				onClick={() => {
					track("exit_and_delete_clicked");
					onClose();
					setOpen(true);
				}}
			>
				{t("app_bar.exit_delete_button")}
			</MenuItem>
			<RegisterAnonModal
				renderButton={(onOpenRegister) => {
					return (
						<Dialog
							open={open}
							onClose={() => {
								track("anon_user_delete_canceled");
								onCloseModal();
							}}
							title={t("anon_mode.delete_account_header")}
						>
							<p>{t("anon_mode.delete_account_body_1")}</p>
							<p>
								<Trans
									i18nKey="anon_mode.delete_account_body_2"
									components={[
										<Link
											key="0"
											component="button"
											type="button"
											onClick={() => {
												onCloseModal();
												onOpenRegister();
											}}
										/>,
									]}
								/>
							</p>
							<DialogActions>
								<Button
									variant="primary ghost"
									onClick={() => {
										track("anon_user_delete_canceled");
										onCloseModal();
									}}
								>
									{t("cancel_button")}
								</Button>
								<Button
									variant="primary"
									onClick={() => {
										track("anon_user_delete_clicked");
										onCloseModal();
										onLogout();
									}}
								>
									{t("delete_button")}
								</Button>
							</DialogActions>
						</Dialog>
					);
				}}
			/>
		</>
	);
}
