import { UserAccount } from "@lib/models";
import { ProfileViewSource } from "@lib/util/trackingHelpers/profileClickAccountInfo";
import ProfileCard from "./ProfileCard";
import ProfileDialog, { RenderOpener } from "./ProfileDialog";

type UserProfileDialogProps = {
	renderOpener: RenderOpener;
	profile: UserAccount;
	source: ProfileViewSource;
};

export default function UserProfileDialog({ profile, renderOpener, source }: UserProfileDialogProps) {
	return (
		<ProfileDialog uuid={profile.uuid} renderOpener={renderOpener} source={source}>
			{({ onClose }) => {
				return (
					<ProfileCard
						{...{
							profile,
							hideChatButton: false,
							profileType: "user",
							onClose: onClose,
							source,
						}}
					/>
				);
			}}
		</ProfileDialog>
	);
}
