import mapboxgl from "mapbox-gl";
import { useEffect, useRef, useState } from "react";
import { Point } from "../MapTypes";
import { ClusterPin, Pin } from "../pin";

const { Marker } = mapboxgl;

const BASE_Z_INDEX = 10;

type MapPointElement = HTMLDivElement;

function createMapPoint(map: mapboxgl.Map, pin: Pin, zIndex: string): mapboxgl.Marker {
	const markerEl: MapPointElement = document.createElement("div");
	markerEl.style.zIndex = zIndex;
	markerEl.dataset.pinId = pin.id;
	if (pin instanceof ClusterPin) {
		markerEl.classList.add("cluster-pin");
	}
	const marker = new Marker({
		element: markerEl,
	})
		.setLngLat([pin.lng, pin.lat])
		.addTo(map);
	return marker;
}

export default function useMakeMarkersFromPins(mapbox: mapboxgl.Map | undefined, points: Pin[]) {
	const renderedPoints = useRef<[Point, mapboxgl.Marker][]>([]);
	const [pointElementMap, setPointElementMap] = useState<Map<Point["id"], MapPointElement>>(() => new Map());
	useEffect(() => {
		if (mapbox) {
			const currentMap = new Map(renderedPoints.current.map(([point, marker]) => [point.id, marker]));
			const newSet = new Set();
			renderedPoints.current = points.flatMap((point, index) => {
				const zIndex = String(index + BASE_Z_INDEX);
				newSet.add(point.id);
				if (currentMap.has(point.id)) {
					const currentPointMarker = currentMap.get(point.id) as mapboxgl.Marker;
					const currentPosition = currentPointMarker.getLngLat();
					if (currentPosition.lat !== point.lat || currentPosition.lng !== point.lng) {
						currentPointMarker.setLngLat([point.lng, point.lat]);
					}
					const markerEl = currentPointMarker.getElement();
					markerEl.style.zIndex = zIndex;
					return [[point, currentPointMarker]];
				}
				const marker = createMapPoint(mapbox, point, zIndex);
				return [[point, marker]];
			});
			currentMap.forEach((marker, id) => {
				if (!newSet.has(id)) {
					marker.remove();
				}
			});
			setPointElementMap(
				new Map(renderedPoints.current.map(([point, marker]) => [point.id, marker.getElement() as MapPointElement])),
			);
		}
	}, [mapbox, points]);
	return pointElementMap;
}
