import { Button } from "@components/Button";
import BoltIcon from "@components/icons/BoltIcon";
import useNavModalState from "@hooks/useNavModalState";
import useUser from "@hooks/useUser";
import InTheMoment from "@lib/feature/inTheMoment";
import { track } from "@lib/util/trackAnalytics";
import { Tooltip } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useCallback } from "react";

type InTheMomentControlSource = "map" | "feed";
type InTheMomentControlType = {
	source: InTheMomentControlSource;
};

export default function InTheMomentControl({ source }: InTheMomentControlType) {
	const { t } = useTranslation("common");
	const { open: dialogOpen, setOpen: setDialogOpen } = useNavModalState("itmc-modal");
	const ownUser = useUser();
	const hasITMC = ownUser?.in_the_moment_status != null;
	const labelTitle = hasITMC ? t("in_the_moment_view_button_label") : t("in_the_moment_button_label");

	const onClick = useCallback(() => {
		track("itm_button_tapped", { source });
		setDialogOpen(true);
	}, [setDialogOpen, source]);

	return (
		<>
			<Tooltip arrow placement="left" title={labelTitle}>
				<Button onClick={onClick} variant="primary" size="xl" aria-label={labelTitle} icon>
					<BoltIcon />
				</Button>
			</Tooltip>
			<InTheMoment
				open={dialogOpen}
				onClose={() => {
					setDialogOpen(false);
				}}
			/>
		</>
	);
}
