import { Button } from "@components/Button";
import BoltIcon from "@components/icons/BoltIcon";
import CheckSquareIcon from "@components/icons/CheckSquareIcon";
import ExclamationSquareIcon from "@components/icons/ExclamationSquareIcon";
import XIcon from "@components/icons/XIcon";
import vcrOsdMono from "@lib/fonts/vcrOsdMono";
import { Severity } from "@lib/models";
import { Box, BoxProps, SvgIconProps, SxProps, Theme, keyframes } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { ReactNode, forwardRef, useLayoutEffect, useRef, useState } from "react";

type AlertProps = Omit<BoxProps, "title"> & {
	severity?: Severity;
	onClose?: () => void;
	button?: ReactNode;
	role?: string;
	sx?: SxProps<Theme>;
} & (
		| {
				title: ReactNode;
				body?: never;
		  }
		| {
				title?: never;
				body: ReactNode;
		  }
		| {
				title: ReactNode;
				body: ReactNode;
		  }
	);

const severityToColor: Record<Severity, { color: string; AlertIcon: (props: SvgIconProps) => React.JSX.Element }> = {
	error: { color: palette.Error, AlertIcon: ExclamationSquareIcon },
	success: { color: palette.Success, AlertIcon: CheckSquareIcon },
	info: { color: palette.Secondary, AlertIcon: BoltIcon },
};

function getSeverityStyles(severity: Severity) {
	return severityToColor[severity];
}

function alertAnimation(severity: Severity) {
	return keyframes({
		"0%": {
			boxShadow: `0 0 4px 4px ${getSeverityStyles(severity).color}`,
		},
		"50%": {
			boxShadow: `0 0 4px 4px ${getSeverityStyles(severity).color}`,
		},
		"100%": {
			boxShadow: "0 0 4px 1px transparent",
		},
	});
}

const Alert = forwardRef(function Alert(
	{ title, body, onClose, severity = "info", button, role = "alert", sx = [], ...boxProps }: AlertProps,
	forwardedRef,
) {
	const { t } = useTranslation("common");
	const { color: severityColor, AlertIcon } = getSeverityStyles(severity);
	const [alignItems, setAlignItems] = useState<"start" | "center">("start");

	const contentRef = useRef<HTMLDivElement>(null);
	const closeButtonRef = useRef<HTMLButtonElement>(null);

	useLayoutEffect(() => {
		const content = contentRef.current;
		const closeButton = closeButtonRef.current;
		if (title != null && body == null) {
			setAlignItems("center");
			return;
		}
		if (content != null && closeButton != null && content.clientHeight <= closeButton.clientHeight) {
			setAlignItems("center");
		}
	}, [title, body]);

	return (
		<Box
			ref={forwardedRef}
			role={role}
			{...boxProps}
			sx={[
				{
					animation: `1s ease 0s ${alertAnimation(severity)}`,
					p: "8px",
					display: "flex",
					gap: "12px",
					maxWidth: "600px",
					minWidth: "fit-content",
					alignItems,
					backgroundColor: palette.surfaceContainer,
					color: palette.onSurface,
					border: `1px solid ${severityColor}`,
					h2: {
						fontFamily: vcrOsdMono.style.fontFamily,
						fontSize: "16px",
						m: 0,
					},
					"& p": {
						margin: 0,
						fontSize: title == null ? "14px" : "16px",
					},
				},
				...(Array.isArray(sx) ? sx : [sx]),
			]}
		>
			<AlertIcon sx={{ width: "24px", height: "24px", p: "2px", color: severityColor }} />
			<Box ref={contentRef} sx={{ display: "flex", flexDirection: "column", gap: "4px", flexGrow: 1 }}>
				{title == null ? null : <h2>{title}</h2>}
				{body == null ? null : <p>{body}</p>}
			</Box>
			<Box sx={{ display: "flex", alignItems: "center", height: "fit-content" }}>
				{button == null ? null : button}
				{onClose == null ? null : (
					<Button
						ref={closeButtonRef}
						variant="secondary ghost"
						icon
						size="xs"
						aria-label={t("close_button_label")}
						onClick={onClose}
					>
						<XIcon />
					</Button>
				)}
			</Box>
		</Box>
	);
});

export default Alert;
