import useImageLoadTransition, { imageLoadTransitionTime } from "@hooks/useImageLoadTransition";
import { UserShort } from "@lib/models";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import { styled } from "@mui/material";
import { memo } from "react";
import { Blurhash } from "react-blurhash";
import UserIconOnMap from "./UserIconOnMap";

const StyledPersonOutlineIcon = styled(PersonOutlineIcon)({
	width: "75%",
	height: "75%",
});

const AvatarStylesWrapper = styled("div")({
	height: "100%",
	width: "100%",
	position: "relative",
	"& > *": {
		position: "absolute",
		top: "0px",
		left: "0px",
		width: "100%",
		height: "100%",
		transition: `opacity ${imageLoadTransitionTime / 1000}s ease`,
	},
});

type AvatarThumbnailMediaProps = {
	profile: UserShort;
	alt: string;
	stayBlurred?: boolean;
};

export default memo(function AvatarThumbnailMedia({ profile, alt, stayBlurred = false }: AvatarThumbnailMediaProps) {
	const blurhash = profile.in_the_moment_status?.blurhash ?? profile.photos.at(0)?.blurhash;
	const image = profile.in_the_moment_status?.thumb ?? profile.photos.at(0)?.thumb ?? null;
	const { hideBlurhash, imageProps } = useImageLoadTransition(image);
	// There is always a blurhash if there are thumbnails.
	if (blurhash == null) {
		if (profile.is_anon) {
			return <StyledPersonOutlineIcon />;
		}
		return <UserIconOnMap />;
	}

	return (
		<AvatarStylesWrapper>
			{stayBlurred || !hideBlurhash ? <Blurhash hash={blurhash} width="100%" height="100%" aria-hidden="true" /> : null}
			{!stayBlurred && typeof image === "string" ? (
				// eslint-disable-next-line @next/next/no-img-element
				<img alt={alt} {...imageProps} />
			) : (
				<p className="sr-only">{alt}</p>
			)}
		</AvatarStylesWrapper>
	);
});
