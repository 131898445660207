import useUser from "@hooks/useUser";
import { Box } from "@mui/material";
import MapLayout from "./MapLayout";

type GlobalMapProps = {
	route: string;
};

export default function GlobalMap({ route }: GlobalMapProps) {
	const ownUser = useUser();
	const isMapRoute = route === "/map" || route === "/anon-sign-up";

	if (ownUser == null && route !== "/anon-sign-up") {
		return null;
	}

	return (
		<Box
			sx={{
				position: "absolute",
				top: 0,
				bottom: 0,
				width: "100%",
				left: isMapRoute ? 0 : -10000,
				display: "flex",
				flexDirection: "column",
				overflow: "hidden",
				visibility: isMapRoute ? "visible" : "hidden",
				zIndex: isMapRoute ? 0 : -10000,
			}}
		>
			<MapLayout />
		</Box>
	);
}
