import { statusReply } from "@api/users";
import Alert from "@components/Alert";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import { updateAlertType } from "@lib/feature/alert/types";
import useAlertState from "@lib/feature/alert/useAlertState";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { track } from "@lib/util/trackAnalytics";
import createDecorator from "final-form-focus";
import { useTranslation } from "next-i18next";
import { Form } from "react-final-form";
import ReplyInput from "./ReplyInput";

type ReplyFormProps = {
	uuid: string;
	onSend: () => void;
};

type FormValues = {
	message: string;
};

const focusOnError = createDecorator<FormValues>();

export default function ReplyForm({ uuid, onSend }: ReplyFormProps) {
	const { t } = useTranslation("common");
	const appUpdateAlert = useUpdateAlert();
	const [alert, localUpdateAlert] = useAlertState();
	const updateAlert: updateAlertType = function updateAlert(message, variant) {
		if (variant === "success") {
			appUpdateAlert(message, variant);
		} else {
			localUpdateAlert(message, variant);
		}
	};
	const onSubmit = makeHandleFormSubmit<FormValues>({
		async submitter(values) {
			await statusReply(uuid, values.message);
			track("reply_to_ITMC_sent");
			onSend();
		},
		updateAlert,
		successMessage: t("in_the_moment.reply_success_message"),
		unknownErrorMessage: t("unknown_error"),
	});
	return (
		<Form<FormValues>
			onSubmit={onSubmit}
			decorators={[focusOnError]}
			validate={(values) => {
				if (values.message == null || values.message.length === 0) {
					return { message: true };
				}
				return undefined;
			}}
			render={({ hasValidationErrors, handleSubmit, submitting }) => (
				<form onSubmit={handleSubmit}>
					{alert == null ? null : (
						<Alert
							sx={{ mx: "12px" }}
							key={alert.key}
							severity={alert.severity}
							body={alert.children}
							onClose={() => {
								updateAlert();
							}}
						/>
					)}
					<ReplyInput disabled={hasValidationErrors || submitting} />
				</form>
			)}
		/>
	);
}
