import CardContent from "@components/CardContent";
import Pronouns from "@components/Pronouns";
import useDisplayName from "@hooks/useDisplayName";
import useIdify from "@hooks/useIdify";
import { AnyAccount } from "@lib/models";
import { Card, Typography } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";
import OnlineStatus from "../OnlineStatus";
import Height from "./Height";
import InlineListItem from "./InlineListItem";
import RelationshipStatus from "./RelationshipStatus";

type BasicInfoProps = {
	profile: AnyAccount;
};

export default function BasicInfo({ profile }: BasicInfoProps) {
	const { t } = useTranslation("common");
	const idify = useIdify();
	const displayName = useDisplayName(profile);
	const sectionId = idify("section");

	const emptyDisplayName = profile.display_name.length === 0;

	return (
		<Card variant="outlined" sx={{ border: 0, background: "none" }}>
			<CardContent
				sx={{
					display: "flex",
					flexDirection: "column",
					gap: 0,
				}}
			>
				<Typography
					component="h2"
					id={sectionId}
					variant="h5"
					sx={{
						fontStyle: emptyDisplayName ? "italic" : undefined,
						fontFamily: "var(--heading-font)",
					}}
				>
					{displayName}
				</Typography>

				<Typography component="p" variant="body2" sx={{ m: 0, mt: 1.5, color: "text.secondary" }}>
					<Pronouns profile={profile} />
				</Typography>

				<Typography variant="body2" sx={{ color: "text.secondary" }}>
					{(() => {
						const infoList: JSX.Element[] = [];
						if (profile.age != null) {
							infoList.push(<InlineListItem key={1} label={t("profile.age_screenreader_label")} value={profile.age} />);
						}
						if (profile.height != null) {
							infoList.push(<Height key={2} height={profile.height} />);
						}
						if (profile.relationships.length !== 0) {
							infoList.push(<RelationshipStatus key={3} relationships={profile.relationships} />);
						}

						if (infoList.length > 1) {
							return (
								<Trans
									i18nKey={
										infoList.length === 2 ? "profile.information_segments_two" : "profile.information_segments_three"
									}
									components={infoList}
								/>
							);
						}
						return infoList;
					})()}
				</Typography>

				<OnlineStatus account={profile} />
			</CardContent>
		</Card>
	);
}
