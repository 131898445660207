import CardContent from "@components/CardContent";
import { FieldTag } from "@components/MultiSelectField/configTypes";
import useIdify from "@hooks/useIdify";
import { AnyAccount } from "@lib/models";
import { Card, Typography } from "@mui/material";
import { ReactNode } from "react";
import { SectionField } from "./SectionField";

export type SectionField = {
	label: string;
	field: keyof AnyAccount;
	values: FieldTag[];
};

type SectionProps = {
	header: string;
	fields?: SectionField[];
	children?: ReactNode;
};

// tested in @lib/components/ProfileDialog/ProfileInfo/index.test.tsx
export default function Section({ header, fields, children }: SectionProps) {
	const idify = useIdify();
	const sectionId = idify("section");
	const labelId = idify("label");

	return (
		<Card
			variant="outlined"
			sx={{
				"& p": {
					m: 0,
				},
			}}
		>
			<CardContent>
				<Typography component="h3" id={sectionId} variant="h5">
					{header}
				</Typography>
				{fields != null
					? fields.map(({ field, label, values }) => (
							<SectionField
								key={field}
								field={field}
								label={label}
								values={values}
								labelId={labelId}
								sectionId={sectionId}
							/>
						))
					: (children ?? null)}
			</CardContent>
		</Card>
	);
}
