import BackButtonV2 from "@components/BackButtonV2";
import { LinkButton } from "@components/Button";
import ChevronLeftIcon from "@components/icons/ChevronLeftIcon";
import RadiusSelector from "@lib/feature/Feed/RadiusSelector";
import { Box, Typography } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { default as NextLink } from "next/link";
import { useRouter } from "next/router";
import { ReactNode, useMemo } from "react";
import AppMenu from "./AppMenu";
import BKDRHeaderLogo from "./BKDRHeaderLogo";

type HomeButtonTarget = "back" | "map";

type TopNavProps = {
	homeButtonTarget?: HomeButtonTarget;
	pageHeading?: ReactNode | undefined;
	toolbarHead?: ReactNode | undefined;
};

type Route = "MAP" | "FEED" | "OTHER";

export default function TopNav({ homeButtonTarget, pageHeading, toolbarHead }: TopNavProps) {
	const { t } = useTranslation("common");
	const router = useRouter();
	const activeRoute = useMemo((): Route => {
		if (router.pathname === "/map" || router.pathname === "/anon-sign-up") {
			return "MAP";
		}
		if (router.pathname.includes("/feed")) {
			return "FEED";
		}
		return "OTHER";
	}, [router.pathname]);

	const isMapRoute = activeRoute === "MAP";
	return (
		<Box
			component="header"
			sx={(theme) => ({
				backgroundColor: isMapRoute ? "transparent" : palette.surfaceContainer,
				borderBottom: pageHeading == null ? "unset" : `1px solid ${palette.onSurface}`,
				display: "flex",
				alignItems: "center",
				gap: theme.spacing(0.5),
				zIndex: isMapRoute ? 1 : 0,
				pointerEvents: isMapRoute ? "none" : "auto",
				pt: "env(safe-area-inset-top, 0)",

				"> *": {
					pointerEvents: "auto",
				},
			})}
		>
			{(() => {
				if (homeButtonTarget === "back") {
					return (
						<BackButtonV2
							sx={{
								ml: 1,
							}}
							onClick={() => {
								router.back();
							}}
						/>
					);
				}
				if (homeButtonTarget === "map") {
					return (
						<LinkButton
							icon
							variant="secondary ghost"
							sx={{ ml: 1 }}
							aria-label={homeButtonTarget === "map" ? t("nav.map_link_label") : t("nav.inbox_link_label")}
							href="/map"
						>
							<ChevronLeftIcon />
						</LinkButton>
					);
				}
				return null;
			})()}
			{toolbarHead ?? (
				<>
					<Typography
						variant="h6"
						noWrap
						component="h1"
						sx={{
							fontFamily: "var(--heading-font)",
							mr: "auto",
							a: {
								color: "inherit",
								textDecoration: "none",
							},
						}}
					>
						{pageHeading ?? (
							<>
								{isMapRoute ? (
									<Box sx={{ display: "flex", alignItems: "end" }}>
										<BKDRHeaderLogo />
									</Box>
								) : (
									<NextLink href="/map" style={{ display: "flex", alignItems: "end" }}>
										<BKDRHeaderLogo />
									</NextLink>
								)}
							</>
						)}
					</Typography>
					{isMapRoute ? (
						<Box sx={{ height: "44px", overflow: "hidden", display: "flex" }}>
							<AppMenu />
						</Box>
					) : activeRoute === "FEED" ? (
						<RadiusSelector />
					) : null}
				</>
			)}
		</Box>
	);
}
