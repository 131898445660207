import Dialog from "@components/Dialog";
import NeedHelpFAQ from "@components/NeedHelpFAQ";
import useLocation from "@hooks/useLocation";
import makeSessionStorageHelper from "@lib/util/makeSessionStorageHelper";
import { useTranslation } from "next-i18next";
import { useEffect, useState } from "react";

const hasBeenClosedStorage = makeSessionStorageHelper<boolean>("/lib/feature/MainView/PreciseLocation");

export default function PreciseLocation() {
	const { t } = useTranslation("common");
	const [locationState] = useLocation();
	const [open, setOpen] = useState(false);

	function onClose() {
		setOpen(false);
		hasBeenClosedStorage.set(true);
	}

	useEffect(() => {
		if (locationState === "denied" && !hasBeenClosedStorage.get()) {
			setOpen(true);
		}
	}, [locationState]);

	return (
		<Dialog open={open} onClose={onClose} title={t("location_request.location_disabled_header")}>
			<p>{t("location_request.location_disabled_body")}</p>
			<p>{t("location_request.location_disabled_body_2")}</p>
			<p>
				<NeedHelpFAQ />
			</p>
		</Dialog>
	);
}
