import { Button } from "@components/Button";
import CornerUpLeftIcon from "@components/icons/CornerUpLeftIcon";
import { InTheMomentStatus } from "@lib/models";
import { track } from "@lib/util/trackAnalytics";
import { useTranslation } from "next-i18next";

type ReplyButtonProps = {
	inTheMomentContent: InTheMomentStatus;
	onOpen: () => void;
	useTextButton?: boolean;
};

export default function ReplyButton({ inTheMomentContent, onOpen, useTextButton }: ReplyButtonProps) {
	const { t } = useTranslation("common");
	const photo = inTheMomentContent?.full;
	const video = inTheMomentContent?.video;
	const message = inTheMomentContent?.message ?? "";

	function onClick() {
		track("reply_to_ITMC_button_tapped", {
			photo: photo != null,
			video: video != null,
			message: message.length > 0,
		});
		onOpen();
	}

	function getAriaLabel() {
		if (message.length === 0) {
			if (video != null) {
				return t("profile.reply_to_this_video");
			}
			if (photo != null) {
				return t("profile.reply_to_this_photo");
			}
		}
		if (video != null) {
			return t("profile.reply_to_this_status_and_video");
		}
		if (photo != null) {
			return t("profile.reply_to_this_status_and_photo");
		}
		return t("profile.reply_to_this_status");
	}

	return useTextButton === true ? (
		<Button size="sm" onClick={onClick} variant="secondary ghost">
			<CornerUpLeftIcon /> {t("reply_button")}
		</Button>
	) : (
		<Button icon size="md" onClick={onClick} variant="primary" aria-label={getAriaLabel()}>
			<CornerUpLeftIcon />
		</Button>
	);
}
