import Dialog from "@components/Dialog";
import SnackbarProvider from "@lib/feature/alert/SnackbarProvider";
import { ReportSource, UserShort } from "@lib/models";
import { useTranslation } from "next-i18next";
import ReportFriendForm from "./ReportFriendForm";

type ReportFriendDialogProps = {
	open: boolean;
	onClose: () => void;
	profile: UserShort;
	onBlock: (successMessage: string) => void;
	source: ReportSource;
};

export default function ReportFriendDialog({ open, onClose, profile, onBlock, source }: ReportFriendDialogProps) {
	const { t } = useTranslation("common");

	return (
		<SnackbarProvider>
			<Dialog open={open} onClose={onClose} title={t("report_dialog.header")} subtitle={t("report_dialog.subheader")}>
				<ReportFriendForm onClose={onClose} profile={profile} onBlock={onBlock} source={source} />
			</Dialog>
		</SnackbarProvider>
	);
}
