import { blockUser as blockUserApi } from "@api/users";
import useDisplayName from "@hooks/useDisplayName";
import useAlertState from "@lib/feature/alert/useAlertState";
import { blockUser } from "@lib/feature/blocking";
import { UserShort } from "@lib/models";
import reportError from "@lib/util/reportError";
import { DialogActions, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useState } from "react";
import Alert from "./Alert";
import { Button } from "./Button";
import Dialog from "./Dialog";

type BlockFriendDialogProps = {
	open: boolean;
	onClose: () => void;
	profile: UserShort;
	onBlock: (successMessage: string) => void;
};
export default function BlockFriendDialog({ open, onClose, profile, onBlock }: BlockFriendDialogProps) {
	const { t } = useTranslation();
	const [alert, updateAlert] = useAlertState();
	const [loading, setLoading] = useState(false);
	const displayName = useDisplayName(profile);

	async function handleBlock() {
		setLoading(true);
		try {
			await blockUserApi({ block: { uuid: profile.uuid } });
			blockUser(profile.uuid);
			onClose();
		} catch (error) {
			reportError(error as Error);
			updateAlert(t("unknown_error"));
			return;
		} finally {
			setLoading(false);
		}
		onBlock(t("block_dialog.success_message"));
	}

	return (
		<Dialog open={open} title={t("block_dialog.header")} subtitle={t("block_dialog.subheader")} onClose={onClose}>
			<Typography>{`${t("block_dialog.profile_name")}: ${displayName}`}</Typography>
			<Typography>{t("block_dialog.body")}</Typography>
			{alert == null ? undefined : (
				<Alert
					key={alert.key}
					severity={alert.severity}
					body={alert.children}
					onClose={() => {
						updateAlert();
					}}
				/>
			)}
			<DialogActions>
				<Button variant="primary" onClick={handleBlock} disabled={loading}>
					{t("block_button")}
				</Button>
			</DialogActions>
		</Dialog>
	);
}
