import useUser from "@hooks/useUser";
import { Box } from "@mui/material";
import Feed from ".";

type GlobalFeedProps = {
	route: string;
};

export default function GlobalFeed({ route }: GlobalFeedProps) {
	const ownUser = useUser();
	const isFeedRoute = route === "/feed";
	if (ownUser == null) {
		return null;
	}
	return (
		<Box
			sx={{
				height: "100%",
				visibility: isFeedRoute ? "visible" : "hidden",
				zIndex: isFeedRoute ? 0 : -10000,
				position: isFeedRoute ? "relative" : "absolute",
				left: isFeedRoute ? "unset" : "-2000px",
				display: "flex",
				flexDirection: "column",
				overflow: "hidden",
			}}
		>
			<Feed />
		</Box>
	);
}
