import PlayIcon from "@components/icons/PlayIcon";
import { Badge } from "@mui/material";
import { styled } from "@mui/material/styles";
import palette from "@styles/palette";
import { ReactNode } from "react";

const StyledBadge = styled(Badge)(({ theme }) => ({
	"& .video-badge.MuiBadge-badge": {
		width: theme.spacing(1.5),
		height: theme.spacing(1.5),
		minWidth: theme.spacing(1.5),
		bottom: theme.spacing(0.75),
		left: theme.spacing(0.75),
		borderRadius: "unset",
		padding: 0,
		backgroundColor: palette.Primary,
	},
}));

type VideoBadgeProps = {
	children: ReactNode;
	videoBadge: boolean;
};
export default function VideoBadge({ videoBadge, children }: VideoBadgeProps) {
	return (
		<StyledBadge
			invisible={videoBadge !== true}
			badgeContent={<PlayIcon sx={{ width: "8px", height: "8px" }} />}
			anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
			slotProps={{ badge: { className: "video-badge" } }}
		>
			{children}
		</StyledBadge>
	);
}
