import { Button } from "@components/Button";
import MenuIcon from "@components/icons/MenuIcon";
import useIdify from "@hooks/useIdify";
import { Box, Menu, MenuItem, Tooltip } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { useRef, useState } from "react";
import AppMenuOptions from "./AppMenuOptions";

export default function AppMenu() {
	const { t } = useTranslation("common");
	const buttonRef = useRef<HTMLButtonElement | null>(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const idify = useIdify();
	const menuId = idify("menu");
	return (
		<Box sx={{ flexGrow: 0, mr: "20px" }}>
			<Tooltip arrow placement="top" title={t("app_bar.settings_menu_button_tooltip")}>
				<Button
					icon
					variant="tertiary"
					aria-label={t("app_bar.settings_menu_button_tooltip")}
					aria-controls={menuId}
					aria-haspopup="true"
					aria-expanded={menuOpen ? "true" : "false"}
					ref={buttonRef}
					active={menuOpen}
					onClick={() => {
						setMenuOpen(true);
					}}
					sx={{
						backdropFilter: "blur(120px)",
						backgroundColor: menuOpen ? undefined : palette.SurfaceContainerOpacity15,
					}}
				>
					<MenuIcon />
				</Button>
			</Tooltip>
			<Menu
				id={menuId}
				MenuListProps={{
					component: "div",
				}}
				slotProps={{
					paper: { sx: { mt: "-1px" } },
				}}
				anchorEl={buttonRef.current}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				keepMounted
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				open={menuOpen}
				onClose={() => {
					setMenuOpen(false);
				}}
			>
				<MenuItem
					component="span"
					onClick={() => {
						setMenuOpen(false);
					}}
					className="sr-only"
				>
					{t("screen_reader_close_menu_button")}
				</MenuItem>
				<AppMenuOptions setMenuOpen={setMenuOpen} />
			</Menu>
		</Box>
	);
}
