import Alert from "@components/Alert";
import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import DialogButton from "@components/DialogButton";
import isIOS from "@lib/util/isIOS";
import { IosShareOutlined, MoreVertOutlined } from "@mui/icons-material";
import { Box, DialogActions } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";
import { ReactNode } from "react";
import { NotificationMessageProps } from "../types";

type IconWithAltProps = {
	children?: ReactNode;
	icon: ReactNode;
};

function IconWithAlt({ children, icon }: IconWithAltProps) {
	return (
		<>
			{icon}
			<span className="sr-only">{children}</span>
		</>
	);
}

export default function InstallPWAMessage({ onClose: handleClose }: NotificationMessageProps) {
	const { t } = useTranslation("common");
	return (
		<Alert
			title={t("install_pwa_banner.banner_title")}
			body={t("install_pwa_banner.banner_subtitle")}
			button={
				<DialogButton
					onClose={handleClose}
					renderDialog={(open, onClose) => {
						return (
							<Dialog open={open} onClose={onClose} title={t("install_pwa_dialog.dialog_title")}>
								<Box component="ol" sx={{ pl: "1.5em" }}>
									<li>
										<Trans
											t={t}
											i18nKey={
												isIOS()
													? "install_pwa_dialog.install_pwa_step_1_ios"
													: "install_pwa_dialog.install_pwa_step_1_android"
											}
											components={[
												<IconWithAlt
													key="0"
													icon={
														isIOS() ? (
															<IosShareOutlined sx={{ verticalAlign: "middle" }} />
														) : (
															<MoreVertOutlined sx={{ verticalAlign: "middle" }} />
														)
													}
												/>,
											]}
										/>
									</li>
									<li>
										{isIOS()
											? t("install_pwa_dialog.install_pwa_step_2_ios")
											: t("install_pwa_dialog.install_pwa_step_2_android")}
									</li>
									<li>
										<Trans t={t} i18nKey={"install_pwa_dialog.install_pwa_step_3"} components={[<strong key="0" />]} />
									</li>
								</Box>
								<DialogActions>
									<Button variant="secondary" onClick={onClose}>
										{t("close_button")}
									</Button>
								</DialogActions>
							</Dialog>
						);
					}}
					renderButton={(onOpen) => (
						<Button onClick={onOpen} size="xxs" variant="primary ghost">
							{t("install_pwa_banner.banner_button_label")}
						</Button>
					)}
					dialogRoutingName="install-pwa"
				/>
			}
		/>
	);
}
