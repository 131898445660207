import { useTranslation } from "next-i18next";
import { ComponentProps, MouseEvent } from "react";
import { Button } from "./Button";
import XIcon from "./icons/XIcon";

type CloseButtonProps = Partial<ComponentProps<typeof Button>> & {
	onClick: (event: MouseEvent) => void;
};

export default function CloseButton(props: CloseButtonProps) {
	const { t } = useTranslation("common");
	return (
		<Button
			icon
			size="xs"
			variant="secondary ghost"
			aria-label={t("close_button_label")}
			{...props}
			sx={{
				position: "absolute",
				right: 8,
				top: 8,
				zIndex: "modal",
				...props.sx,
			}}
		>
			<XIcon />
		</Button>
	);
}
