import { track } from "@lib/util/trackAnalytics";
import { Snackbar, SnackbarContent } from "@mui/material";
import { useEffect } from "react";
import useCurrentNotification from "./useCurrentNotification";

export default function NotificationViewer() {
	const currentNotification = useCurrentNotification();

	useEffect(() => {
		if (currentNotification != null) {
			track("alert_viewed", { name: currentNotification.analyticsName });
		}
	}, [currentNotification]);

	if (currentNotification == null) {
		return null;
	}

	return (
		<Snackbar
			anchorOrigin={{ vertical: "top", horizontal: "center" }}
			open={true}
			sx={(theme) => ({
				"&.MuiSnackbar-root": {
					zIndex: "modal",
					width: "100%",
					left: "50%",
					transform: "translateX(-50%)",
					maxWidth: "min(100% - 40px, 600px)",
					top: "calc(64px + env(safe-area-inset-top, 0))",
				},
				"& .MuiPaper-root": {
					background: "none",
					boxShadow: "none",
					width: "fit-content",
					p: 0,
					[theme.breakpoints.up("sm")]: {
						width: "100%",
					},
				},
			})}
		>
			<SnackbarContent
				role="presentation"
				message={<currentNotification.Message onClose={currentNotification.onClose} />}
				sx={{
					"& .MuiSnackbarContent-message": {
						p: 0,
						width: "100%",
					},
				}}
			/>
		</Snackbar>
	);
}
