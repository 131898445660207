import { SvgIcon, SvgIconProps } from "@mui/material";

export default function XIcon(props: SvgIconProps) {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" {...props} data-testid="BKDRXIcon">
			<path
				d="M6.00015 4.58594L12.0002 10.5859L18.0002 4.58594L19.4144 6.00015L13.4144 12.0002L19.4144 18.0002L18.0002 19.4144L12.0002 13.4144L6.00015 19.4144L4.58594 18.0002L10.5859 12.0002L4.58594 6.00015L6.00015 4.58594Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
