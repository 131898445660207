import Alert from "@components/Alert";
import { Button } from "@components/Button";
import { useTranslation } from "next-i18next";
import RegisterAnonModal from "./RegisterAnonModal";

export default function AnonMapToast() {
	const { t } = useTranslation("common");

	return (
		<Alert
			role="presentation"
			title={t("anon_mode.toast_header")}
			button={
				<RegisterAnonModal
					renderButton={(onOpen) => (
						<Button variant="primary ghost" onClick={onOpen}>
							{t("register_button")}
						</Button>
					)}
				/>
			}
		/>
	);
}
