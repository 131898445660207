import MessageSquareLinesIcon from "@components/icons/MessageSquareLinesIcon";
import useUnreadCount from "@lib/feature/chat/useUnreadCount";
import { Badge, SvgIconProps } from "@mui/material";

export default function ChatsNotificationIcon(props: SvgIconProps) {
	const unreadCount = useUnreadCount();
	if (unreadCount === 0) {
		return <MessageSquareLinesIcon {...props} />;
	}
	return (
		<Badge badgeContent={unreadCount} color="secondary">
			<MessageSquareLinesIcon {...props} />
		</Badge>
	);
}
