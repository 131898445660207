import AppLayout from "@components/AppLayout";
import AlertContainer from "@components/AppLayout/AlertContainer";
import useIsAnon from "@hooks/useIsAnon";
import { Box } from "@mui/material";
import { useRouter } from "next/router";
import AnonMapToast from "../anon/AnonMapToast";
import NotificationViewer from "../notification/NotificationViewer";
import MapView from "./MapView";
import PreciseLocation from "./PreciseLocation";
import useMapStatus from "./useMapStatus";

export default function MapLayout() {
	const mapRendered = useMapStatus();
	const isAnon = useIsAnon();
	const { pathname } = useRouter();

	return (
		<AppLayout>
			<Box
				component="main"
				sx={{
					flex: "1 1 auto",
					display: "flex",
					flexDirection: "column",
					alignItems: "stretch",
					justifyContent: "stretch",
				}}
			>
				<Box sx={{ position: "absolute", inset: 0, bottom: "56px" }}>
					<MapView />
				</Box>
				{(() => {
					if (isAnon) {
						return (
							<AlertContainer>
								<AnonMapToast />
							</AlertContainer>
						);
					}
					if (mapRendered && isAnon === false) {
						return <NotificationViewer />;
					}
					return null;
				})()}
				{pathname === "/map" ? <PreciseLocation /> : null}
			</Box>
		</AppLayout>
	);
}
