import useIdify from "@hooks/useIdify";
import vcrOsdMono from "@lib/fonts/vcrOsdMono";
import { Box, BoxProps, Modal, ModalProps, styled } from "@mui/material";
import palette from "@styles/palette";
import { ComponentProps, ElementType, MouseEvent, ReactNode } from "react";
import CloseButton from "./CloseButton";

export const DIALOG_VERTICAL_SPACING = "16px";

const DialogContainer = styled("div")({
	position: "absolute",
	left: "0px",
	right: "0px",
	top: "var(--safe-top)",
	bottom: 0, // not safe area because absolute is relative to <html>, which currently
	// doesn't cover the bottom safe area.
	display: "flex",
	justifyContent: "center",
	alignItems: "center",
	padding: "20px",
	pointerEvents: "none",
});

const DialogTop = styled("div")({
	display: "flex",
	gap: "4px",

	div: {
		flex: "1 1 auto",
	},

	h2: {
		margin: 0,
		fontFamily: vcrOsdMono.style.fontFamily,
		padding: "0px",
		fontWeight: "400",
		fontSize: "20px",
	},
});

const DialogBody = styled("div")({
	width: "100%",
	maxWidth: "600px",
	maxHeight: "100%",
	backgroundColor: palette.surfaceContainer,
	pointerEvents: "auto",
	position: "relative",
	display: "flex",
	flexDirection: "column",
	padding: `${DIALOG_VERTICAL_SPACING} 24px`,
	overflowY: "auto",

	"& p": {
		fontSize: "14px",
		margin: 0,
	},
});

type DialogProps = Omit<ModalProps, "children" | "title" | "onClose"> & {
	onClose?: (event: MouseEvent, reason: "backdropClick" | "escapeKeyDown" | "closeButtonClick") => void;
	children: ReactNode;
	title: ReactNode;
	subtitle?: ReactNode;
	contentComponent?: ElementType;
	dialogContainerProps?: ComponentProps<typeof DialogContainer>;
	dialogBodyProps?: ComponentProps<typeof DialogBody>;
	dialogContentProps?: BoxProps;
};

export default function Dialog({
	children,
	title,
	subtitle,
	onClose,
	contentComponent,
	dialogContainerProps,
	dialogBodyProps,
	dialogContentProps,
	...props
}: DialogProps) {
	const idify = useIdify();
	const titleId = idify("title");
	const subtitleId = idify("subtitle");
	return (
		<Modal onClose={onClose} {...props}>
			<DialogContainer {...dialogContainerProps}>
				<DialogBody
					role="dialog"
					tabIndex={-1}
					aria-labelledby={titleId}
					aria-describedby={subtitle == null ? undefined : subtitleId}
					aria-modal="true"
					{...dialogBodyProps}
				>
					<DialogTop>
						<div>
							<h2 id={titleId}>{title}</h2>
							{subtitle == null ? null : <p id={subtitleId}>{subtitle}</p>}
						</div>
						{onClose == null ? null : (
							<CloseButton
								onClick={(event) => onClose(event, "closeButtonClick")}
								sx={{
									position: "static",
									top: "auto",
									right: "auto",
									flex: "0 0 auto",
								}}
							/>
						)}
					</DialogTop>
					<Box
						component={contentComponent ?? "div"}
						{...dialogContentProps}
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: DIALOG_VERTICAL_SPACING,
							marginTop: DIALOG_VERTICAL_SPACING,
							...dialogContentProps?.sx,
						}}
					>
						{children}
					</Box>
				</DialogBody>
			</DialogContainer>
		</Modal>
	);
}
