import { track } from "@lib/util/trackAnalytics";
import { ElementType, useState } from "react";
import { useInterval } from "react-timers-hooks";
import checkShouldAddNotification from "./checkShouldAddNotification";
import dismissNotification from "./dismissNotification";
import AllowNotificationsMessage from "./message/AllowNotificationsMessage";
import InstallPWAMessage from "./message/InstallPWAMessage";
import UnsupportedBrowserMessage from "./message/UnsupportedBrowserMessage";
import UpdateIOSMessage from "./message/UpdateIOSMessage";
import { getCurrentNotification, releaseCurrentNotification } from "./storage";
import { MessageType, NotificationMessageProps, SurveyNotificationMessageProps } from "./types";
import useServerNotification from "./useServerNotification";

const ONE_MINUTE = 60000;

const messageMap: Record<MessageType, ElementType<NotificationMessageProps>> = {
	install_pwa: InstallPWAMessage,
	allow_notifications: AllowNotificationsMessage,
	unsupported_browser: UnsupportedBrowserMessage,
	update_ios: UpdateIOSMessage,
};

type CurrentNotification =
	| null
	| {
			Message: ElementType<NotificationMessageProps>;
			onClose: () => void;
			analyticsName: string;
	  }
	| {
			Message: ElementType<SurveyNotificationMessageProps>;
			onClose: (linkClicked: boolean) => void;
			analyticsName: string;
	  };

export default function useCurrentNotification(): CurrentNotification {
	const serverNotification = useServerNotification();

	const [localNotification, setLocalNotification] = useState(() => {
		checkShouldAddNotification();
		return getCurrentNotification();
	});

	useInterval(() => {
		checkShouldAddNotification();
		setLocalNotification(getCurrentNotification());
	}, ONE_MINUTE);

	if (serverNotification != null) {
		return serverNotification;
	}

	if (localNotification == null) {
		return null;
	}

	const Message = messageMap[localNotification.messageType];
	const onClose = function () {
		dismissNotification(localNotification.messageType);
		releaseCurrentNotification();
		setLocalNotification(getCurrentNotification());
		track("alert_closed", { name: localNotification.messageType });
	};

	return {
		Message,
		onClose,
		analyticsName: localNotification.messageType,
	};
}
