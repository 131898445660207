import BlockReportMenu from "@components/BlockReportMenu";
import Distance from "@components/Distance";
import { useLocaleWithCountry } from "@hooks/useLocale";
import useTimeSinceITMCreated from "@hooks/useTimeSinceITMCreated";
import useUser from "@hooks/useUser";
import ITMReply from "@lib/feature/inTheMoment/ITMReply";
import { FeedItem } from "@lib/models";
import getTimeDistance from "@lib/util/getTimeDistance";
import { styled } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import { useMemo } from "react";
import Content from "./Content";
import FeedAvatar from "./FeedAvatar";
import NewContentBadge from "./NewContentBadge";

const Card = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: theme.spacing(1.5),
	padding: theme.spacing(0, 1.25),
}));

const CardHeader = styled("div")(({ theme }) => ({
	display: "flex",
	gap: theme.spacing(1.5),

	"& > .vertical": {
		flex: "1 1 auto",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-start",
		gap: theme.spacing(0.5),

		"& > *": {
			flex: "0 1 auto",
		},

		".upperrow": {
			alignSelf: "stretch",
			display: "flex",
			alignContent: "center",
			gap: theme.spacing(0.5),
		},

		".username, .timedistance": {
			fontSize: "12px",
			fontWeight: 500,
			color: palette.onSurface,
		},
		".timedistance": {
			color: palette.OnSurfaceSecondary,
			marginRight: "auto",
		},
	},
}));

const CardActions = styled("div")(({ theme }) => ({
	display: "flex",
	justifyContent: "space-between",
	paddingLeft: theme.spacing(5),
}));

type ITMCFeedItemProps = {
	feedItem: FeedItem;
};

export default function ITMCFeedItem({ feedItem }: ITMCFeedItemProps) {
	const { t } = useTranslation("common");
	const locale = useLocaleWithCountry();
	const { in_the_moment_status, user_short } = feedItem.content;
	const timeSinceCreation: number = useTimeSinceITMCreated(in_the_moment_status.created_at);
	const timeDistance = useMemo(() => {
		return getTimeDistance(timeSinceCreation, locale);
	}, [timeSinceCreation, locale]);
	const { pathname } = useRouter();
	const ownUser = useUser();
	const postIsOwn = ownUser?.uuid === feedItem.content.user_short.uuid;
	return (
		<Card>
			<CardHeader>
				<FeedAvatar user_short={user_short} />
				<div className="vertical">
					<span className="upperrow">
						<span className="username">
							{user_short.is_anon
								? t("anon_display_name")
								: user_short.display_name === ""
									? t("no_username_placeholder")
									: user_short.display_name}
						</span>
						<span className="timedistance">{timeDistance}</span>
						<NewContentBadge itmc={in_the_moment_status} />
					</span>
					<Distance account={user_short} />
				</div>
			</CardHeader>
			<Content in_the_moment_status={in_the_moment_status} />
			<CardActions>
				{postIsOwn ? null : (
					<>
						{pathname === "/feed" ? <ITMReply profile={user_short} useTextButton /> : null}
						<BlockReportMenu
							profile={user_short}
							onBlock={() => undefined}
							source="feed"
							popinDirectionX="left"
							menuDirection="up"
						/>
					</>
				)}
			</CardActions>
		</Card>
	);
}
