import { reportUser } from "@api/users";
import Alert from "@components/Alert";
import { Button } from "@components/Button";
import { DIALOG_VERTICAL_SPACING } from "@components/Dialog";
import useDisplayName from "@hooks/useDisplayName";
import { useLocaleWithCountry } from "@hooks/useLocale";
import { useUpdateAlert } from "@lib/feature/alert/notificationContext";
import useAlertState from "@lib/feature/alert/useAlertState";
import { blockUser } from "@lib/feature/blocking";
import { ReportSource, Severity, UserShort } from "@lib/models";
import { makeHandleFormSubmit } from "@lib/util/makeHandleFormSubmit";
import { Box, DialogActions, Typography } from "@mui/material";
import createDecorator from "final-form-focus";
import { useTranslation } from "next-i18next";
import { ReactNode, useState } from "react";
import { Form } from "react-final-form";
import BlockField from "./BlockField";
import ExplanationField from "./ExplanationField";
import ReasonsField from "./ReasonsField";

type ReportFormValues = {
	reasons: string[];
	explanation: string;
	block: boolean;
};

const focusOnError = createDecorator<ReportFormValues>();

const initialValues: Partial<ReportFormValues> = {
	reasons: [],
	explanation: undefined,
	block: false,
};

function validateForm(values: Partial<ReportFormValues>) {
	return {
		reasons: (values.reasons?.length ?? 0) > 0 ? undefined : true,
		explanation: (values.explanation ?? "").length > 0 ? undefined : [{ t: "field_required_error" }],
	};
}

type ReportFriendFormProps = {
	onClose: () => void;
	profile: UserShort;
	onBlock: (successMessage: string) => void;
	source: ReportSource;
};

export default function ReportFriendForm({ onClose, profile, onBlock, source }: ReportFriendFormProps) {
	const { t } = useTranslation();
	const locale = useLocaleWithCountry();
	const listFormatter = new Intl.ListFormat(locale, { style: "short", type: "unit" });
	const [step, setStep] = useState<1 | 2>(1);
	const [alert, updateAlert] = useAlertState();
	const updateAlertSuccess = useUpdateAlert();
	const displayName = useDisplayName(profile);

	const onSubmit = makeHandleFormSubmit<ReportFormValues>({
		async submitter(values) {
			await reportUser({
				report: {
					uuid: profile.uuid,
					reasons: values.reasons,
					explanation: values.explanation,
					block: values.block,
					source,
				},
			});
			onClose();
			if (values.block) {
				blockUser(profile.uuid);
				onBlock(t("report_dialog.success_message"));
			}
			return undefined;
		},
		successMessage: t("report_dialog.success_message"),
		unknownErrorMessage: t("unknown_error"),
		updateAlert: (message?: ReactNode, severity?: Severity) => {
			if (severity === "success") {
				updateAlertSuccess(message, severity);
			} else {
				updateAlert(message, severity);
			}
		},
	});

	return (
		<Form<ReportFormValues>
			onSubmit={onSubmit}
			initialValues={initialValues}
			validate={validateForm}
			decorators={[focusOnError]}
			render={({ handleSubmit, values, hasValidationErrors, submitting }) => {
				return (
					<Box
						component="form"
						onSubmit={handleSubmit}
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: DIALOG_VERTICAL_SPACING,
						}}
					>
						<Typography color="text.secondary" variant="body2">
							{t("report_dialog.profile_name")}{" "}
							<Typography component="span" color="text.primary">
								{displayName}
							</Typography>
						</Typography>
						{step === 1 ? <ReasonsField /> : null}
						{step === 2 ? (
							<>
								<Typography color="text.secondary" variant="body2">
									{t("report_dialog.reasons")}{" "}
									<Typography component="span" color="text.primary">
										{listFormatter.format(values.reasons.map((key) => t(`report_dialog.reason.${key}`)))}
									</Typography>
								</Typography>
								<ExplanationField />
								<BlockField />
							</>
						) : null}
						{alert == null ? undefined : (
							<Alert
								key={alert.key}
								severity={alert.severity}
								body={alert.children}
								onClose={() => {
									updateAlert();
								}}
							/>
						)}
						<DialogActions>
							{step === 1 ? (
								<>
									<Button variant="primary ghost" onClick={onClose}>
										{t("cancel_button")}
									</Button>
									<Button
										key="next"
										variant="primary"
										onClick={() => {
											if (values.reasons.length > 0) {
												setStep(2);
											} else {
												updateAlert(t("report_dialog.error_select_a_reason"), "error");
											}
										}}
									>
										{t("next_button")}
									</Button>
								</>
							) : (
								<>
									<Button
										variant="primary ghost"
										onClick={() => {
											setStep(1);
										}}
										disabled={submitting}
									>
										{t("back_button")}
									</Button>
									<Button
										// adding a key is necessary or the click on Next also triggers a
										// submit event from this button, since React only updates the existing
										// element with no key here
										key="submit"
										type="submit"
										variant="primary"
										disabled={submitting || hasValidationErrors}
									>
										{t("report_button")}
									</Button>
								</>
							)}
						</DialogActions>
					</Box>
				);
			}}
		/>
	);
}
