// tested in ITMCOverlay.test.tsx
import ITMCBadge from "@components/ITMCBadge";
import { useLocaleWithCountry } from "@hooks/useLocale";
import useTimeSinceITMCreated from "@hooks/useTimeSinceITMCreated";
import getTimeDistance from "@lib/util/getTimeDistance";
import { useMemo } from "react";

export function ITMCTimestamp({ createdAt }: { createdAt: string }) {
	const locale = useLocaleWithCountry();
	const timeSinceCreation: number = useTimeSinceITMCreated(createdAt);

	const timeDistance = useMemo(() => {
		return getTimeDistance(timeSinceCreation, locale);
	}, [timeSinceCreation, locale]);

	if (timeDistance == null) {
		return null;
	}
	return <ITMCBadge sx={{ width: "fit-content" }}>{timeDistance}</ITMCBadge>;
}
