import Alert from "@components/Alert";
import { Snackbar } from "@mui/material";
import { ReactNode } from "react";
import { NotificationProvider } from "./notificationContext";
import useAlertState from "./useAlertState";

const autoHideDuration = 3000;

type SnackbarProviderProps = {
	children: ReactNode;
};

export default function SnackbarProvider({ children }: SnackbarProviderProps) {
	const [alert, updateAlert] = useAlertState();
	const autoHide = (alert?.severity ?? "success") === "success";
	return (
		<NotificationProvider value={updateAlert}>
			{children}
			<Snackbar
				open={alert != null}
				autoHideDuration={autoHide ? autoHideDuration : null}
				anchorOrigin={{ vertical: "top", horizontal: "center" }}
				sx={{ display: "flex", flexDirection: "column", alignItems: "stretch" }}
				onClose={() => {
					updateAlert();
				}}
			>
				{alert == null ? undefined : (
					<Alert
						key={alert.key}
						severity={alert.severity}
						body={alert.children}
						onClose={() => {
							updateAlert();
						}}
					/>
				)}
			</Snackbar>
		</NotificationProvider>
	);
}
