import { captureException, withScope } from "@sentry/nextjs";
import { AxiosError } from "axios";

export default function reportError(error: Error) {
	if (process.env.NODE_ENV === "test") {
		return;
	}

	// eslint-disable-next-line no-console
	console.error(error);

	if (error instanceof AxiosError) {
		withScope(function (scope) {
			scope.setFingerprint([
				error.config?.method ?? "unknown method",
				error.config?.url?.split("?")[0] ?? "unknown url",
				error.response?.status?.toString() ?? "unknown status code",
			]);
			captureException(error);
		});
	} else {
		captureException(error);
	}
}
