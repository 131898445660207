import { styled } from "@mui/material/styles";

const AlertContainer = styled("div")(({ theme }) => ({
	position: "relative",
	top: "20px",
	left: "50%",
	transform: "translateX(-50%)",
	maxWidth: "min(100% - 40px, 600px)",
	zIndex: theme.zIndex.snackbar,
}));

export default AlertContainer;
