import Alert from "@components/Alert";
import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import DialogButton from "@components/DialogButton";
import isIOS from "@lib/util/isIOS";
import { DialogActions } from "@mui/material";
import { useTranslation } from "next-i18next";
import { NotificationMessageProps } from "../types";

export default function UnsupportedBrowserMessage({ onClose: handleClose }: NotificationMessageProps) {
	const { t } = useTranslation("common");
	return (
		<Alert
			title={t("unsupported_browser_banner.banner_title")}
			body={t("unsupported_browser_banner.banner_subtitle")}
			button={
				<DialogButton
					renderButton={(onOpen) => (
						<Button onClick={onOpen} size="xxs" variant="primary ghost">
							{t("unsupported_browser_banner.banner_button_label")}
						</Button>
					)}
					onClose={handleClose}
					renderDialog={(open, onClose) => {
						return (
							<Dialog open={open} onClose={onClose} title={t("unsupported_browser_dialog.dialog_title")}>
								<p>
									{t("unsupported_browser_dialog.dialog_body", {
										option: isIOS() ? "ios" : "other",
									})}
								</p>
								<DialogActions>
									<Button variant="primary" onClick={onClose}>
										{t("close_button")}
									</Button>
								</DialogActions>
							</Dialog>
						);
					}}
					dialogRoutingName="unsupported-browser"
				/>
			}
		/>
	);
}
