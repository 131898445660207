import useNavModalState from "@hooks/useNavModalState";
import { ReactNode, useCallback } from "react";
import { Button, ButtonProps } from "./Button";

type DialogButtonProps = {
	children?: ReactNode;
	renderDialog: (open: boolean, onClose: () => void) => ReactNode;
	renderButton?: (onOpen: () => void) => ReactNode;
	dialogRoutingName: string;
	onClose?: () => void;
	buttonProps?: ButtonProps;
};

export default function DialogButton({
	children,
	renderDialog,
	dialogRoutingName,
	onClose,
	renderButton,
	buttonProps,
}: DialogButtonProps) {
	const { open, setOpen } = useNavModalState(dialogRoutingName);
	const onOpen = useCallback(() => {
		setOpen(true);
	}, [setOpen]);
	const handleClose = useCallback(() => {
		if (onClose != null) {
			onClose();
		}
		setOpen(false);
	}, [setOpen, onClose]);

	const newButtonProps = buttonProps ?? { variant: "primary" };

	return (
		<>
			{(() => {
				if (renderButton != null) {
					return renderButton(onOpen);
				}
				return (
					<Button {...newButtonProps} type="button" onClick={onOpen}>
						{children}
					</Button>
				);
			})()}
			{renderDialog(open, handleClose)}
		</>
	);
}
