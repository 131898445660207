import MediaButton from "@components/MediaButton";
import VideoRecorder from "@components/VideoRecorder";
import useObjectUrl from "@hooks/useObjectUrl";
import DeleteIcon from "@mui/icons-material/Delete";
import VideocamIcon from "@mui/icons-material/Videocam";
import { Box, IconButton } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useField } from "react-final-form";

const previewWidth = 135;

function identity<T>(a: T) {
	return a;
}

export default function VideoField() {
	const { t } = useTranslation("common");

	const { input, meta } = useField<Blob | undefined>("video", {
		format: identity,
	});

	const previewUrl = useObjectUrl(input.value);

	return (
		<VideoRecorder onFinish={input.onChange}>
			{(onOpen) => (
				<Box sx={{ position: "relative" }}>
					<MediaButton
						component="button"
						type="button"
						aria-label={t("in_the_moment.video_button_label")}
						onClick={onOpen}
						disabled={meta.submitting}
						sx={{
							backgroundColor: "inherit",
							color: "inherit",
							width: `${previewWidth}px`,
							height: `${previewWidth}px`,
							zIndex: 0,
						}}
					>
						{previewUrl != null ? (
							<Box
								component="video"
								disablePictureInPicture
								muted
								playsInline
								autoPlay
								src={previewUrl}
								sx={{
									position: "absolute",
									top: "0px",
									right: "0px",
									width: `${previewWidth}px`,
									height: `${previewWidth}px`,
									zIndex: -1,
								}}
							/>
						) : null}
						<VideocamIcon fontSize="large" />
					</MediaButton>
					{input.value == null ? null : (
						<IconButton
							color="secondary"
							size="small"
							aria-label={t("in_the_moment.remove_selected_video_button_screen_reader_label")}
							onClick={() => {
								input.onChange(undefined);
							}}
							disabled={meta.submitting}
							sx={{
								position: "absolute",
								color: "common.white",
								top: (theme) => theme.spacing(1),
								left: (theme) => theme.spacing(1),
							}}
						>
							<DeleteIcon />
						</IconButton>
					)}
				</Box>
			)}
		</VideoRecorder>
	);
}
