import useDisplayName from "@hooks/useDisplayName";
import useUser from "@hooks/useUser";
import { AnyAccount } from "@lib/models";
import { getOnlineStatus } from "@lib/util/getOnlineStatus";
import { Card, Typography } from "@mui/material";
import { useTranslation } from "next-i18next";

type CompatibilityBoxProps = {
	profile: AnyAccount;
};

export default function CompatibilityBox({ profile }: CompatibilityBoxProps) {
	const user = useUser();
	const { t } = useTranslation("common");
	const name = useDisplayName(profile);
	if (
		// prettier
		user == null ||
		profile.uuid === user.uuid ||
		!profile.is_match ||
		profile.in_the_moment_status != null ||
		["online", "idle"].includes(getOnlineStatus(profile))
	) {
		return null;
	}
	return (
		<Card
			variant="outlined"
			sx={{
				borderColor: (theme) => (profile.is_breadcrumb ? theme.palette.info.light : theme.palette.success.light),
				background: "none",
			}}
		>
			<Typography sx={{ p: 2 }} variant="body2">
				{profile.is_breadcrumb ? t("profile.breadcrumb_match_message", { name }) : t("profile.match_message", { name })}
			</Typography>
		</Card>
	);
}
