import GenericMedia from "@components/GenericMedia";
import { AnyAccount } from "@lib/models";
import { ComponentProps, ReactNode } from "react";

export type ProfilePictureKind = "in_the_moment" | "in_the_moment_video" | "profile";

export type ProfilePictureType = {
	uuid: string;
	type: ProfilePictureKind;
	processing: boolean;
	pinned: boolean;
	render: (alt: string, mediaProps?: Partial<ComponentProps<typeof GenericMedia>>, alwaysBlur?: boolean) => ReactNode;
} & (
	| {
			type: "profile";
			createdAt: null;
			pinned: boolean;
	  }
	| {
			type: "in_the_moment";
			createdAt: string;
			pinned: false;
	  }
	| {
			type: "in_the_moment_video";
			createdAt: string;
			pinned: false;
	  }
);

export type ProfilePicturesListType = ProfilePictureType[];

export default function getProfilePictures(
	account: AnyAccount | undefined,
	profileOnly = false,
): ProfilePicturesListType {
	if (account == null) {
		return [];
	}

	const media: ProfilePicturesListType = [];

	if (!profileOnly && account.in_the_moment_status?.blurhash != null) {
		const inTheMomentStatus = account.in_the_moment_status;
		media.push({
			render(alt, mediaProps, alwaysBlur) {
				return (
					<GenericMedia
						alt={alt}
						{...mediaProps}
						media={{
							imageUrl: alwaysBlur ? null : inTheMomentStatus.full,
							videoUrl: alwaysBlur ? null : inTheMomentStatus.video,
							blurhash: inTheMomentStatus.blurhash,
						}}
					/>
				);
			},
			processing: inTheMomentStatus.processing,
			createdAt: inTheMomentStatus.created_at,
			type: inTheMomentStatus.video != null ? ("in_the_moment_video" as const) : ("in_the_moment" as const),
			uuid: inTheMomentStatus.uuid,
			pinned: false,
		});
	}

	return media.concat(
		account.photos.map((photo) => {
			return {
				render(alt, mediaProps, alwaysBlur) {
					return (
						<GenericMedia
							alt={alt}
							{...mediaProps}
							media={{
								imageUrl: alwaysBlur ? null : photo.full,
								videoUrl: null,
								blurhash: photo.blurhash,
							}}
						/>
					);
				},
				processing: photo.processing,
				createdAt: null,
				type: "profile",
				uuid: photo.uuid,
				pinned: photo.pinned,
			};
		}),
	);
}
