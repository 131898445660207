import { accountRegisterPath } from "@api/account";
import { surveySeen } from "@api/survey";
import { track } from "@lib/util/trackAnalytics";
import { ElementType } from "react";
import { useSWRConfig } from "swr";
import useFeatureFlags from "../featureFlags/useFeatureFlags";
import { BKDRAug2024HiringLink, NPSSurveyAvailableMessage } from "./message/SurveyAvailableMessage";
import { SurveyNotificationMessageProps } from "./types";

type CurrentNotification = null | {
	Message: ElementType<SurveyNotificationMessageProps>;
	onClose: (linkClicked: boolean) => void;
	analyticsName: string;
};

export default function useServerNotification(): CurrentNotification {
	const flags = useFeatureFlags();
	const { mutate } = useSWRConfig();
	const surveyName = flags?.show_survey;

	if (surveyName != null) {
		return {
			Message: surveyName === "bkdr_hiring_aug_2024" ? BKDRAug2024HiringLink : NPSSurveyAvailableMessage,
			onClose: async (linkClicked: boolean) => {
				await surveySeen({ survey: { name: surveyName } });
				await mutate(accountRegisterPath);
				if (linkClicked) {
					track("alert_link_clicked", { name: surveyName });
				} else {
					track("alert_closed", { name: surveyName });
				}
			},
			analyticsName: surveyName,
		};
	}

	return null;
}
