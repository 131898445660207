import Dialog from "@components/Dialog";
import { enableTrackingPersistence, hasOptedIn } from "@lib/util/trackAnalytics";
import { Backdrop } from "@mui/material";
import palette from "@styles/palette";
import { useTranslation } from "next-i18next";
import { memo, useEffect, useState } from "react";
import { useGate } from "statsig-react";
import ModalContent from "./ModalContent";

const WAIT_TO_SHOW_DURATION = 5000; // 5 seconds

export default memo(function PrivacyCheckModal() {
	const { t } = useTranslation("common");
	const needsConsentNotice = useGate("use_privacy_consent").value;
	const [show, setShow] = useState(false);
	const [modalOpen, setModalOpen] = useState(false);

	useEffect(() => {
		if (!hasOptedIn()) {
			setShow(true);
		}
	}, []);

	function onClose() {
		setShow(false);
		enableTrackingPersistence({ optedIn: true });
	}

	useEffect(() => {
		if (!needsConsentNotice) {
			enableTrackingPersistence({ optedIn: false });
		}
	}, [needsConsentNotice]);

	useEffect(() => {
		if (show && !modalOpen) {
			const timeout = setTimeout(() => {
				setModalOpen(true);
			}, WAIT_TO_SHOW_DURATION);
			return () => {
				clearTimeout(timeout);
			};
		}
	}, [show, modalOpen]);

	if (!needsConsentNotice) {
		return null;
	}

	return (
		<Backdrop
			open={show}
			invisible={!modalOpen}
			sx={{
				zIndex: "modal",
				backgroundColor: modalOpen ? palette.Scrim : "transparent",
			}}
			unmountOnExit
			onClick={() => {
				if (!modalOpen) {
					setModalOpen(true);
				}
			}}
		>
			<Dialog
				open={modalOpen}
				hideBackdrop
				onClick={() => {
					if (!modalOpen) {
						setModalOpen(true);
					}
				}}
				sx={{
					color: palette.onSurface,
					"& .MuiDialog-container": {
						alignItems: "end",
					},
				}}
				title={t("privacy_check.dialog_heading")}
				dialogContainerProps={{
					sx: {
						flexDirection: "column",
						justifyContent: "flex-end",
						pb: "80px",
					},
				}}
			>
				<ModalContent
					onClose={() => {
						setModalOpen(false);
						onClose();
					}}
				/>
			</Dialog>
		</Backdrop>
	);
});
