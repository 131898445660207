import { PresenceType } from "@lib/models";
import palette from "@styles/palette";

const colorMap: Record<PresenceType, string> = {
	online: palette.indicatorActive,
	idle: palette.OnSurfaceSecondary,
	away: palette.OnSurfaceSecondary,
	offline: palette.OnSurfaceSecondary,
};

export default function getStatusColor(onlineStatus: PresenceType): string {
	return colorMap[onlineStatus];
}
