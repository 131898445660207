import { BadgeProps, styled } from "@mui/material";
import palette from "@styles/palette";

const StyledNoteBadge = styled("span")(({ theme }) => ({
	position: "absolute",
	top: "0%",
	left: "0%",
	bottom: "0%",
	margin: "auto 0px",
	transform: "translateX(-50%)",
	height: theme.spacing(1.75),
	padding: "2px 6px 3px",
	backgroundColor: palette.surfaceContainer,
	display: "flex",
	alignItems: "center",
	fontSize: ".625rem",
	color: palette.onSurface,
	fontFamily: "var(--heading-font)",
}));

export default function NoteBadge(props: BadgeProps) {
	return <StyledNoteBadge {...props} />;
}
