import Link from "@components/Link";
import ArrowUpRightIcon from "@components/icons/ArrowUpRightIcon";
import useLocale from "@hooks/useLocale";
import getSupportLinks from "@lib/util/getSupportLinks";
import { Box } from "@mui/material";
import { Trans, useTranslation } from "next-i18next";

type AnonFormLinksProps = {
	forgotPasswordLink?: boolean;
};

export default function AnonFormLinks({ forgotPasswordLink }: AnonFormLinksProps) {
	const { t } = useTranslation("common");
	const locale = useLocale();
	const externalLink = getSupportLinks("faq", locale);

	return (
		<Box
			sx={(theme) => ({
				display: "flex",
				flexDirection: "column",
				gap: theme.spacing(1),
				a: {
					width: "fit-content",
				},
			})}
		>
			<Link href="/login" variant="body2">
				{t("log_in_instead_link")}
			</Link>
			{forgotPasswordLink ? (
				<Link href="/forgot-password" variant="body2">
					{t("forgot_password_link")}
				</Link>
			) : null}
			<Link href={externalLink} variant="body2" target="_blank">
				<Trans t={t} i18nKey={"faq_page_link"} components={[<span className="sr-only" key="0" />]} />{" "}
				<ArrowUpRightIcon
					sx={(theme) => ({ verticalAlign: "text-bottom", width: theme.spacing(2), height: theme.spacing(2) })}
				/>
			</Link>
		</Box>
	);
}
