import { Badge, BadgeProps } from "@mui/material";
import { styled } from "@mui/material/styles";
import palette from "@styles/palette";
import { ReactNode } from "react";
import { AvatarPinBadgeOption } from "./AvatarPin";

type VideoBadgeProps = {
	badge: AvatarPinBadgeOption;
	children: ReactNode;
};

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
	"& .MuiBadge-badge": {
		width: theme.spacing(1),
		minWidth: theme.spacing(1),
		height: theme.spacing(1),
		border: `1px solid ${palette.surfaceContainer}`,
		borderRadius: theme.spacing(1),
		padding: 0,
		bottom: theme.spacing(0.25),
		right: theme.spacing(0.25),
		backgroundColor: palette.indicatorActive,
	},
}));

export default function OnlineStatusBadge({ badge, children }: VideoBadgeProps) {
	return (
		<StyledBadge
			invisible={badge !== "online"}
			anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
			overlap="rectangular"
			badgeContent=""
		>
			{children}
		</StyledBadge>
	);
}
