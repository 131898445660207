import useUser from "@hooks/useUser";
import { ReportSource, UserShort } from "@lib/models";
import Block from "@mui/icons-material/Block";
import Flag from "@mui/icons-material/Flag";
import { Divider, Menu, MenuItem, PopoverOrigin, Tooltip } from "@mui/material";
import { useTranslation } from "next-i18next";
import { useRef, useState } from "react";
import BlockFriendDialog from "./BlockFriendDialog";
import { Button } from "./Button";
import ReportFriendDialog from "./ReportFriendDialog";
import DotsVerticalIcon from "./icons/DotsVerticalIcon";

type BlockReportMenuProps = {
	profile: UserShort;
	onBlock: (successMessage: string) => void;
	source: ReportSource;
	popinDirectionX: "left" | "right";
	menuDirection: "up" | "down";
};

export default function BlockReportMenu({
	profile,
	onBlock,
	source,
	popinDirectionX,
	menuDirection,
}: BlockReportMenuProps) {
	const { t } = useTranslation("common");
	const anchorEl = useRef<HTMLButtonElement | null>(null);
	const [menuOpen, setMenuOpen] = useState(false);
	const [dialogOpen, setDialogOpen] = useState<"block" | "report" | false>(false);
	const user = useUser();

	function handleOpenMenu() {
		setMenuOpen(true);
	}

	function handleClose() {
		setMenuOpen(false);
	}

	function handleBlock() {
		setDialogOpen("block");
	}

	function handleReport() {
		setDialogOpen("report");
	}

	function handleCloseDialog() {
		setDialogOpen(false);
	}

	const menuOrigin: PopoverOrigin = {
		vertical: menuDirection === "up" ? "bottom" : "top",
		horizontal: popinDirectionX === "right" ? "left" : "right",
	};

	return (
		<>
			<Tooltip arrow placement={popinDirectionX} title={t("more_button")}>
				<Button
					icon
					variant="secondary ghost"
					size="sm"
					aria-label={t("more_button")}
					onClick={handleOpenMenu}
					ref={anchorEl}
					sx={{ marginLeft: "auto" }}
				>
					<DotsVerticalIcon />
				</Button>
			</Tooltip>
			<Menu
				anchorEl={anchorEl.current}
				open={menuOpen}
				onClose={handleClose}
				onClick={handleClose}
				anchorOrigin={menuOrigin}
				transformOrigin={menuOrigin}
			>
				{user?.is_anon
					? null
					: [
							<MenuItem key="report-button" onClick={handleReport}>
								<Flag />
								{t("profile_more_menu.report")}
							</MenuItem>,
							<Divider key="divider" />,
						]}
				<MenuItem onClick={handleBlock}>
					<Block />
					{t("profile_more_menu.block")}
				</MenuItem>
			</Menu>
			{user?.is_anon ? null : (
				<ReportFriendDialog
					profile={profile}
					open={dialogOpen === "report"}
					onClose={handleCloseDialog}
					onBlock={onBlock}
					source={source}
				/>
			)}
			<BlockFriendDialog
				profile={profile}
				open={dialogOpen === "block"}
				onClose={handleCloseDialog}
				onBlock={onBlock}
			/>
		</>
	);
}
