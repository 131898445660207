import Alert from "@components/Alert";
import { Button } from "@components/Button";
import Dialog from "@components/Dialog";
import DialogButton from "@components/DialogButton";
import checkForNotification from "@lib/util/checkForNotification";
import registerForPushNotifications from "@lib/util/registerForPushNotifications";
import { DialogActions } from "@mui/material";
import { useTranslation } from "next-i18next";
import { NotificationMessageProps } from "../types";

export default function AllowNotificationsMessage({ onClose: handleClose }: NotificationMessageProps) {
	const { t } = useTranslation("common");

	const denied = checkForNotification() && Notification.permission === "denied";

	return (
		<Alert
			title={t("enable_notifications_banner.banner_title")}
			body={t("enable_notifications_banner.banner_subtitle")}
			button={
				denied ? (
					<DialogButton
						onClose={handleClose}
						renderDialog={(open, onClose) => {
							return (
								<Dialog open={open} onClose={onClose} title={t("notifications_disabled_dialog.dialog_title")}>
									<p>{t("notifications_disabled_dialog.reenable_instructions")}</p>
									<DialogActions>
										<Button variant="secondary" onClick={onClose}>
											{t("close_button")}
										</Button>
									</DialogActions>
								</Dialog>
							);
						}}
						dialogRoutingName="allow-notifications"
						renderButton={(onOpen) => (
							<Button size="xxs" variant="primary ghost" onClick={onOpen}>
								{t("enable_button")}
							</Button>
						)}
					/>
				) : (
					<Button
						size="xxs"
						variant="secondary ghost"
						onClick={async () => {
							await registerForPushNotifications();
							handleClose();
						}}
					>
						{t("enable_button")}
					</Button>
				)
			}
		/>
	);
}
