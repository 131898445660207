globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {"id":"5SfjfcyMkz22pQNmL2MDd"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs";
const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;
if (process.env.NODE_ENV === "production") {
	Sentry.init({
		dsn: SENTRY_DSN || "https://8978b16568c24195bb9f5b3f4edc91be@o1376461.ingest.sentry.io/6685516",
		release: process.env.SENTRY_RELEASE ?? "unknown-in-client",

		// Adjust this value in production, or use tracesSampler for greater control
		tracesSampleRate: 0.1,

		// Setting this option to true will print useful information to the console while you're setting up Sentry.
		debug: false,

		replaysOnErrorSampleRate: 1.0,

		// This sets the sample rate to be 10%. You may want this to be 100% while
		// in development and sample at a lower rate in production
		replaysSessionSampleRate: 0.1,
		integrations: [Sentry.extraErrorDataIntegration(), Sentry.httpClientIntegration()],
	});
}
