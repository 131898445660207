import mapboxgl, { LngLatLike, MapboxOptions } from "mapbox-gl";
const accessToken = process.env.NEXT_PUBLIC_MAPBOX_API_TOKEN;

type MapboxInstance = { mapbox: mapboxgl.Map | undefined };
const mapboxInstance: MapboxInstance = { mapbox: undefined };
export type InitMap = {
	container: HTMLDivElement;
	mapConfig: MapboxOptions;
	center: LngLatLike;
	zoom: number;
};

export function initMap(props: MapboxOptions): mapboxgl.Map {
	if (mapboxInstance.mapbox == null) {
		mapboxInstance.mapbox = new mapboxgl.Map({
			...props,
			accessToken,
		});
	}
	return mapboxInstance.mapbox;
}

export function getMap(): mapboxgl.Map | undefined {
	return mapboxInstance.mapbox;
}

export function clearMap() {
	mapboxInstance.mapbox = undefined;
}
