import MapProfileAvatar from "@components/MapProfileAvatar";
import UserProfileDialog from "@components/ProfileDialog/UserProfileDialog";
import useAccount from "@hooks/useAccount";
import useFeatureFlags from "@lib/feature/featureFlags/useFeatureFlags";
import assertType from "@lib/util/assertType";
import { Typography } from "@mui/material";
import mapboxgl from "mapbox-gl";
import { useTranslation } from "next-i18next";
import { memo, useEffect, useState } from "react";

const MIN_FULL_PIN_ZOOM = 12;

type UserMapMarkerProps = {
	mapbox: mapboxgl.Map;
};

export default memo(function UserMapMarker({ mapbox }: UserMapMarkerProps) {
	const user = assertType(useAccount());
	const { t } = useTranslation("common");
	const featureFlags = useFeatureFlags();
	const clusteringEnabled = featureFlags?.admin_clustering_enabled === true;

	const [showFullPin, setShowFullPin] = useState(() =>
		clusteringEnabled ? mapbox.getZoom() >= MIN_FULL_PIN_ZOOM : true,
	);
	useEffect(() => {
		if (!clusteringEnabled) {
			setShowFullPin(true);
			return;
		}
		function onMove() {
			setShowFullPin(mapbox.getZoom() >= MIN_FULL_PIN_ZOOM);
		}
		mapbox.on("move", onMove);
		return () => {
			mapbox.off("move", onMove);
		};
	}, [clusteringEnabled, mapbox]);

	if (!showFullPin) {
		return (
			<Typography
				sx={(theme) => ({
					backgroundColor: theme.palette.common.white,
					color: theme.palette.getContrastText(theme.palette.common.white),
					px: 0.75,
					borderRadius: "13px",
					fontSize: "10px",
				})}
			>
				{t("map_profile_avatar.self_label")}
			</Typography>
		);
	}

	return (
		<UserProfileDialog
			renderOpener={(onOpen) => <MapProfileAvatar account={user} ownProfile onClick={onOpen} />}
			profile={user}
			source="map"
		/>
	);
});
