import { Box } from "@mui/material";
import mapboxgl, { LngLatLike } from "mapbox-gl";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import styles from "../customMapControl.module.css";
import MapControls from "./MapControls";

class MapControl {
	_map: mapboxgl.Map | undefined;
	_container: HTMLDivElement;
	constructor(container: HTMLDivElement) {
		this._container = container;
	}
	onAdd(map: mapboxgl.Map) {
		this._map = map;
		return this._container;
	}
	onRemove() {
		this._container.parentNode?.removeChild(this._container);
		this._map = undefined;
	}
}

export type BottomRightMapControlsProps = {
	mapbox: mapboxgl.Map;
	center: LngLatLike;
};

export default function BottomRightMapControls({ mapbox, center }: BottomRightMapControlsProps) {
	const [element, setElement] = useState<HTMLDivElement | null>(null);
	useEffect(() => {
		const containerElement = document.createElement("div");
		containerElement.classList.add(styles.customBottomRightMapControls);
		const control = new MapControl(containerElement);
		mapbox.addControl(control, "bottom-right");
		setElement(containerElement);
		return () => {
			mapbox.removeControl(control);
		};
	}, [mapbox]);

	if (element == null) {
		return null;
	}
	return createPortal(
		<Box sx={{ display: "flex", flexDirection: "column", gap: "10px", alignItems: "end" }}>
			<MapControls mapbox={mapbox} center={center} />
		</Box>,
		element,
	);
}
