import { SvgIcon, SvgIconProps } from "@mui/material";

export default function ExclamationSquareIcon(props: SvgIconProps) {
	return (
		<SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" data-testid="BKDRExclamationSquareIcon" {...props}>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M22 2H2V22H22V2ZM13 14V6H11V14H13ZM13.01 16H11V18H13.01V16Z"
				fill="currentColor"
			/>
		</SvgIcon>
	);
}
